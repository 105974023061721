<template>
  <div class="buyPageWrap">
    <m-layout-bar>
      <div class="buyWrap">
        <div class="banner">
          <img :src="require('../assets/image/buy/banner.png')" alt />
        </div>
        <div class="content">
          <div class="fixedMenus" :class="barFixed?'show':''">
            <a
              class="fixedMenu shouBanLi trans"
              :class="{'active':activeName==='shouBanLi'}"
              data-name="shouBanLi"
              @click="clickMenuItem('shouBanLi',$event)"
            >
              <img class="icon" :src="require('../assets/image/buy/item_1_f.png')" alt />
              <div class="rightLayout">
                <span>手办礼</span>
                <img class="sub" :src="require('../assets/image/buy/sub_1.png')" alt />
              </div>
            </a>
            <a
              class="fixedMenu ip trans"
              :class="{'active':activeName==='ip'}"
              data-name="ip"
              @click="clickMenuItem('ip',$event)"
            >
              <img class="icon" :src="require('../assets/image/buy/item_2_f.png')" alt />
              <div class="rightLayout">
                <span>IP萌物</span>
                <img class="sub" :src="require('../assets/image/buy/sub_2.png')" alt />
              </div>
            </a>
            <a
              class="fixedMenu kangYang trans"
              :class="{'active':activeName==='kangYang'}"
              data-name="kangYang"
              @click="clickMenuItem('kangYang',$event)"
            >
              <img class="icon" :src="require('../assets/image/buy/item_3_f.png')" alt />
              <div class="rightLayout">
                <span>康养佳品</span>
                <img class="sub" :src="require('../assets/image/buy/sub_3.png')" alt />
              </div>
            </a>
            <a
              class="fixedMenu meiWu trans"
              :class="{'active':activeName==='meiWu'}"
              data-name="meiWu"
              @click="clickMenuItem('meiWu',$event)"
            >
              <img class="icon" :src="require('../assets/image/buy/item_4_f.png')" alt />
              <div class="rightLayout">
                <span>养颜美物</span>
                <img class="sub" :src="require('../assets/image/buy/sub_4.png')" alt />
              </div>
            </a>
          </div>
          <div class="menusWrap">
            <div
              id="mShouBanLi"
              class="menuWrap shouBanLi maskWrap size toShow"
              :class="{'active':activeName==='shouBanLi'}"
              data-name="shouBanLi"
              @click="clickMenuItem('shouBanLi',$event)"
            >
              <a>
                <img :src="require('../assets/image/buy/item_1.png')" alt />
              </a>
            </div>
            <div
              class="menuWrap IP maskWrap size toShow"
              :class="{'active':activeName==='ip'}"
              data-name="ip"
              @click="clickMenuItem('ip',$event)"
            >
              <a>
                <img :src="require('../assets/image/buy/item_2.png')" alt />
              </a>
            </div>
            <div
              class="menuWrap kangYang maskWrap size toShow"
              :class="{'active':activeName==='kangYang'}"
              data-name="kangYang"
              @click="clickMenuItem('kangYang',$event)"
            >
              <a>
                <img :src="require('../assets/image/buy/item_3.png')" alt />
              </a>
            </div>
            <div
              class="menuWrap yangYan maskWrap size toShow"
              :class="{'active':activeName==='meiWu'}"
              data-name="meiWu"
              @click="clickMenuItem('meiWu',$event)"
            >
              <a>
                <img :src="require('../assets/image/buy/item_4.png')" alt />
              </a>
            </div>
          </div>

          <!-- 伴手礼 g1-->
          <div id="shouBanLi" class="shouBanLiWrap group">
            <div class="titleWrap">
              <img :src="require('../assets/image/buy/title1.png')" alt />
            </div>
            <div class="pics">
              <m-pic-summary-bar
                v-for="(item,index) in banShouLiList"
                :key="index"
                class="item"
                :src="baseUrl + item.goods_image"
                :title="item.goods_name"
                detailFontColor="#d3d3d3"
                :detail="item.goods_info"
                :imgWidth="440"
                :imgHeight="295"
                itemtype="伴手礼"
              ></m-pic-summary-bar>
            </div>
          </div>

          <!-- IP萌物 g2 -->
          <div id="ip" class="IPWrap group">
            <div class="titleWrap">
              <img :src="require('../assets/image/buy/title2.png')" alt />
            </div>
            <div class="pics">
              <m-pic-summary-bar
                v-for="(item,index) in iPList"
                :key="index"
                class="item"
                :src="baseUrl + item.goods_image"
                :title="item.goods_name"
                detailFontColor="#d3d3d3"
                :detail="item.goods_info"
                :imgWidth="440"
                :imgHeight="295"
                itemtype="IP萌物"
              ></m-pic-summary-bar>
            </div>
          </div>

          <!-- 康氧佳品 g3 -->
          <div id="kangYang" class="KangYangWrap group">
            <div class="titleWrap">
              <img :src="require('../assets/image/buy/title3.png')" alt />
            </div>
            <div class="pics">
              <m-pic-summary-bar
                v-for="(item,index) in kangYangList"
                :key="index"
                class="item"
                :src="baseUrl + item.goods_image"
                :title="item.goods_name"
                detailFontColor="#d3d3d3"
                :detail="item.goods_info"
                :imgWidth="440"
                :imgHeight="295"
                itemtype="康养佳品"
              ></m-pic-summary-bar>
            </div>
          </div>

          <!-- 养颜美物 g4 -->
          <div id="meiWu" class="MeiYanWrap group">
            <div class="titleWrap">
              <img :src="require('../assets/image/buy/title4.png')" alt />
            </div>
            <div class="pics">
              <m-pic-summary-bar
                v-for="(item,index) in meiYangList"
                :key="index"
                class="item"
                :src="baseUrl + item.goods_image"
                :title="item.goods_name"
                detailFontColor="#d3d3d3"
                :detail="item.goods_info"
                :imgWidth="440"
                :imgHeight="295"
                itemtype="养颜美物"
              ></m-pic-summary-bar>
            </div>
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar";
import TitleGapBar from "@/components/TitleGapBar";
import PicSummaryBar from "@/components/picSummaryBar";
// import { checkIsNumber } from "@/utils/util.js";
import axios from "axios";
import config from "@/utils/config";

export default {
  components: {
    [LayoutBar.name]: LayoutBar,
    [TitleGapBar.name]: TitleGapBar,
    [PicSummaryBar.name]: PicSummaryBar
  },
  data() {
    return {
      menuList: [
        {
          num: 1,
          name: "蛋卷",
          src: "../assets/image/buy/banShouLi_1.png.png",
          detail:
            "村域面积达2.13平方公里，是一个主要以铁皮石斛为核心，集合主题游乐、田园休闲、旅居度假、科普教育为,铁定溜溜位于温州市大荆镇，村域面积达2.13平方公里"
        },
        {
          num: 2,
          name: "蛋卷",
          src: "../assets/image/buy/banShouLi_1.png.png",
          detail:
            "村域面积达2.13平方公里，是一个主要以铁皮石斛为核心，集合主题游乐、田园休闲、旅居度假、科普教育为,铁定溜溜位于温州市大荆镇，村域面积达2.13平方公里"
        },
        {
          num: 3,
          name: "年轮蛋糕",
          src: "../assets/image/buy/banShouLi_1.png.png",
          detail: "村域面积达2.13平方公里。"
        }
      ],
      barFixed: false,
      activeName: "shouBanLi",
      banShouLiList: [{}],
      iPList: new Array(),
      kangYangList: new Array(),
      meiYangList: new Array()
    };
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.getData(config.buyUrlShouBanLi);
    this.getData(config.buyUrlIP);
    this.getData(config.buyUrlKangYang);
    this.getData(config.buyUrlMeiYan);
  },
  methods: {
    async getData(url) {
      try {
        let res = await axios.get(url);
        if (res.data.code == "0" && res.data.data instanceof Array) {
          if (res.data.data.length > 0) {
            let tempList = url.split("=");
            let type = "";
            if (tempList.length >= 2) {
              type = tempList[1];
            }

            if (type == "伴手礼") {
              this.banShouLiList = JSON.parse(JSON.stringify(res.data.data));
            } else if (type == "IP萌物") {
              this.iPList = JSON.parse(JSON.stringify(res.data.data));
            } else if (type == "康养佳品") {
              this.kangYangList = JSON.parse(JSON.stringify(res.data.data));
            } else if (type == "养颜美物") {
              this.meiYangList = JSON.parse(JSON.stringify(res.data.data));
            }

            // console.log(
            //   "res.data.data:",
            //   "1:",
            //   this.banShouLiList,
            //   "2:",
            //   this.iPList,
            //   "3:",
            //   this.kangYangList,
            //   "4:",
            //   this.meiYangList
            // );
          }
        }
      } catch (e) {
        console.log("res err:", e);
      }

      // axios
      //   .get(url)
      //   .then(res => {

      //     this.infoData = res.data.data;
      //     console.log("res.data", res.data.data);
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (!document.querySelector("#mShouBanLi")) return;
      let distOffsetTop = document.querySelector("#mShouBanLi").offsetTop;

      if (parseInt(distOffsetTop).toString() === "NaN") {
        return;
      }
      if (scrollTop > distOffsetTop - 100) {
        this.barFixed = true;
      } else {
        this.barFixed = false;
      }

      //滚动到"手办礼"的范围700
      if (scrollTop > 700) {
        this.activeName = "shouBanLi";
      }
      // 滚动到“IP萌物”的范围1400
      if (scrollTop > 1400) {
        this.activeName = "ip";
      }

      //滚动到“康养佳品”的范围2116
      if (scrollTop > 2116) {
        this.activeName = "kangYang";
      }

      //滚动到“美颜美物”的范围2816
      if (scrollTop > 2816) {
        this.activeName = "meiWu";
      }

      // console.log("window scrollTop:", scrollTop);
      // console.log("目标 distOffsetTop:", distOffsetTop);
      // console.log("fixed标识：", this.barFixed);
    },
    goNavArea(anchorId) {
      let anchorElement = document.getElementById(anchorId);
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    },
    // 点击按钮，实现选中效果
    clickMenuItem(anchorId, e) {
      // console.log(e.currentTarget.getAttributeNode("data-name"));
      if (!e.currentTarget.getAttributeNode("data-name")) return;
      this.activeName = e.currentTarget.getAttributeNode("data-name").value;
      if (!this.activeName) {
        this.activeName = "";
      }
      this.goNavArea(anchorId);
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.buyPageWrap {
  border-bottom: 1px solid transparent;
}
// 公用-------------
.titleWrap {
  width: 529px;
  margin: 0 auto;
  text-align: center;
  // margin-top: 113px;
  .titleGap {
    margin: 0 auto;
  }
}
.pics {
  display: flex;
  justify-content: space-between;
  margin-top: 58px;
}
.buyWrap {
  // background: red;
  border-bottom: 1px solid transparent;
  background: $main-bgYellowLight;
  > .banner {
    > img {
      width: 100%;
    }
  }
  .content {
    width: 1400px;
    margin: 0 auto;
    border-bottom: 1px solid transparent;
    margin-bottom: 70px;

    .fixedMenus {
      position: fixed;
      top: 120px;
      width: 1400px;
      z-index: 99998;
      display: none;
      margin: 0 auto;

      &.show {
        display: block;
      }
      > .fixedMenu {
        display: inline-block;
        width: 349px;
        height: 80px;
        line-height: 80px;
        background: $font-grayDarkD;
        font-size: 28px;
        font-weight: bold;
        color: $main-white;
        text-align: center;
        border-right: 1px solid $font-grayDark;
        cursor: pointer;

        > .icon {
          margin-right: 18px;
          vertical-align: sub;
        }
        > .rightLayout {
          display: inline-block;
          vertical-align: middle;
          > .sub,
          > span {
            display: block;
          }
          > span {
            margin-bottom: 7px;
            line-height: 1.15;
          }
        }
        &.shouBanLi {
          border-bottom: 10px solid $bg-green;
          // border-bottom-left-radius: 10px;
          &:hover,
          &.active {
            background: $bg-green;
          }
        }
        &.ip {
          border-bottom: 10px solid $bg-yellow;
          &:hover,
          &.active {
            background: $bg-yellow;
          }
        }
        &.kangYang {
          border-bottom: 10px solid $bg-greengreen;
          &:hover,
          &.active {
            background: $bg-greengreen;
          }
        }
        &.meiWu {
          border-bottom: 10px solid $bg-blueDark;
          // border-bottom-right-radius: 10px;
          border-right: 0;
          &:hover,
          &.active {
            background: $bg-blueDark;
          }
        }
      }
      > .fixedMenu:nth-of-type(4) {
        width: 350px;
        border-right: 0px;
        // margin-left:5px;
      }
    }

    .menusWrap {
      display: flex;
      margin-top: 90px;
      justify-content: space-between;
      .menuWrap {
        position: relative;
        text-align: center;
        // flex: 1;
      }
    }
    > .group {
      padding-top: 113px;
    }
  }
}
</style>
